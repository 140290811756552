<template>
  <section class="post-info">
    <div class="inner">
      <h1>{{ TITLE }}</h1>
      <div class="hr top"></div>
      <video v-if="VIDEO !== ''" :src="VIDEO" controls autoplay muted></video>
      <img v-else :src="IMAGE" alt="">
      <p>{{ TEXT }}</p>
      <img v-if="VIDEO !== ''" :src="IMAGE" alt="">
      <img v-if="SECOND_IMAGE !== ''" :src="SECOND_IMAGE" alt="">
      <div class="hr bottom"></div>
      <h2>Related posts</h2>
      <div class="related">
        <Post v-for="p in relatedPosts" :key="p.id" :post="p"></Post>
      </div>
    </div>
  </section>
</template>

<script>
import api from "../api/api";
import Post from "../components/Post";

export default {
  name: "PostInfo",
  components: {Post},
  data() {
    return {
      post: {},
      relatedPosts: []
    }
  },
  computed: {
    TITLE() {
      if (!this.post.id) return ''
      return this.post.title[this.$store.getters.LANG]
    },
    TEXT() {
      if (!this.post.id) return ''
      return this.post.text[this.$store.getters.LANG]
    },
    IMAGE() {
      if (!this.post.id) return ''
      let path = '/b/'
      if (this.post.images) path += this.post.images.split(',')[0]
      return path
    },
    SECOND_IMAGE() {
      if (!this.post.id) return ''
      if (this.post.images) {
        const array = this.post.images.split(',')
        if (array.length > 1) return '/b/' + array[1]
        else return ''
      }
      return ''
    },
    VIDEO() {
      if (!this.post.id) return ''
      if (this.post.video) return '/b/' + this.post.video
      return ''
    }
  },
  created() {
    // api.getData('get/b', true, this.$route.params.id).then(r => {
    //   console.log('post resp: ', r)
    //   if (!r.data.error) {
    //     this.post = r.data.post || {}
    //   }
    // })
    api.getData('get/b').then(r => {
      if (!r.data.error) {
        const posts = r.data.posts || []
        for (let i = 0; i < posts.length; i++) {
          if (posts[i].id === +this.$route.params.id) this.post = posts[i]
          else this.relatedPosts.push(posts[i])
        }
      }
    })
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/post_info.scss"></style>
