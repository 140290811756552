<template>
  <div class="post">
    <a :href="'/info/b/' + post.id"></a>
    <div class="imgbox"><img :src="titleImage()" alt=""></div>
    <div class="desc">
      <div>
        <h1>{{ post.title[$store.getters.LANG] }}</h1>
        <p>{{ post.text[$store.getters.LANG] }}</p>
      </div>
      <div>
        <div class="hr"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlogItem",
  props: {
    post: {type: Object, default: () => { return {} }}
  },
  methods: {
    dateString(unixDate) {
      const date = new Date(unixDate * 1000)
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    },
    titleImage() {
      let path = '/b/'
      if (this.post.images !== '') {
        path += this.post.images.split(',')[0]
      }
      return path
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/post.scss"></style>
